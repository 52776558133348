<!--
 * @Author: Dyf
 * @LastEditors: Please set LastEditors
 * @Date: 2023-04-03 10:33:41
 * @LastEditTime: 2023-11-17 14:37:35
 * @Descripttion: 【作业考试】校内作业
-->
<style lang="scss" scoped>
.homework {
  @include innerPage;
  @include pageHead(-10px);
  @include defalutTable(calc(100% - 224px), 38px);
  @include pageFoot;

  .page-head {
    height: 224px;
    flex-wrap: wrap;
    padding-top: 0;

    .head-wrapper.column {
      margin-top: 12px;
    }

    .page-tab {
      justify-content: flex-start;
      margin-left: 24%;

      .tabs {
        border-bottom: none;
        justify-content: flex-start;
      }

      .more-subject {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #6c4ecb;
        margin-right: auto;
        margin-left: 60px;
        margin-top: 6px;
        cursor: pointer;

        &:hover {
          background: #8971d5;
        }

        .iconfont {
          font-size: 18px;
          text-align: center;
          line-height: 24px;
          color: #fff;
        }
      }
    }

    .single-search {
      width: 90.64%;

      .el-button:not(.search) {
        margin-left: 10px;
      }
    }
  }
}

.dialog-container {
  &--distribute {
    box-sizing: border-box;
    padding: 24px 0;

    .date-picker {
      ::v-deep {
        .el-input {
          width: 100%;

          &__inner {
            padding: 0 44px 0 26px;
          }
        }
      }
    }
  }

  &--homework {
    box-sizing: border-box;
    padding: 46px 0 42px 0;

    .form-wrapper {
      width: calc(100% + 32px);
      height:calc(100vh - 260px);
      box-sizing: border-box;
      box-sizing: border-box;
      padding: 0 146px 0 56px;
      overflow: hidden;
      overflow-y: auto;

      .el-select {
        margin-bottom: 12px;
      }

      .homework-questions {
        width: calc(100% + 202px);
        margin-left: -56px;
        box-sizing: border-box;
        padding: 40px 136px 0 0;
        margin-top: 30px;
        border-top: 1px solid rgba($color: #9e9fa2, $alpha: 0.18);

        .dialog-question-list {
          margin-top: 0;
          width: 100%;

          .question--data h5 .el-image {
            margin-right: 0;
          }

          .question-item {
            position: relative;
          }
        }

        .operate-group {
          width: 114px;
          position: absolute;
          top: -4px;
          right: -120px;
          @include flexBox;
        }
      }
    }

    .el-form-item:last-child {
      margin-bottom: 0;
    }
    ::v-deep .el-textarea__inner {
      min-height: 110px !important;
    }
  }
  
}
::v-deep .el-dialog__footer{
  position: sticky;
  bottom: 0;
  background-color: #FFFFFF;
}

.dialog {
  &-question {
    box-sizing: border-box;
    padding: 40px 8px 40px 32px;

    &.detail {
      .dialog-question--head {
        width: 484px;
      }

      .question--data {
        margin-right: 40px;
      }
    }

    &--head {
      width: 432px;
      margin-left: 18px;
      box-sizing: border-box;
    }

    &--title,
    &--desc {
      width: 100%;
      min-height: 46px;
      border-radius: 10px;
      background: #f0f0f0;
      box-sizing: border-box;
      padding: 10px 25px;
      line-height: 26px;
      margin-bottom: 10px;
    }

    &--desc {
      @include flexBox;
      align-items: baseline;

      span {
        flex-shrink: 0;
      }
    }

    &-list {
      margin-top: 28px;

      .operate-group {
        width: 116px;
        flex-shrink: 0;
        margin-left: 10px;
        text-align: left;
      }

      .question,
      .subtopic {
        &-item {
          width: 100%;
          margin-bottom: 28px;
          @include flexBox;
          align-items: baseline;

          &:last-child {
            margin-bottom: 0;
          }

          &.combination {
            .combination--title {
              font-size: 16px;
              color: #404040;
              line-height: 24px;
              margin: 18px 0;
            }
          }
        }

        &--num,
        &--type {
          flex-shrink: 0;
          line-height: 24px;
        }

        &--num {
          color: #1f1f1f;
          font-size: 16px;
        }

        &--type {
          height: 24px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 0 8px 0 4px;
          font-size: 12px;
          padding: 0 8px;
        }

        &--data {
          flex-grow: 1;

          h5 {
            line-height: 24px;
            color: #1f1f1f;
            @include flexBox;
            align-items: baseline;

            span {
              text-align: justify;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }
        }

        &--options {
          margin-top: 12px;

          .option {
            line-height: 24px;
            color: #666666;
            @include flexBox;
            align-items: baseline;
            margin: 10px 0;

            .el-image {
              margin-left: 10px;
              flex-shrink: 0;
              margin-top: -4px;
            }
          }
        }

        &--knowledge,
        &--answer {
          width: 100%;
          min-height: 46px;
          border-radius: 10px;
          background: #f0f0f0;
          box-sizing: border-box;
          padding: 10px 25px;
          line-height: 26px;
          text-align: justify;
        }

        &--knowledge {
          margin-top: 18px;
        }

        &--answer {
          margin-top: 14px;
        }
      }

      .subtopic {
        &--num {
          font-size: 14px;
        }

        &--type {
          margin-right: 6px;
        }

        &--title {
          line-height: 24px;
          @include flexBox;
          align-items: baseline;

          span {
            text-align: justify;
            margin-right: 10px;
          }

          .el-image {
            flex-shrink: 0;
            margin-top: -7px;
          }
        }

        &--options,
        &--knowledge,
        &--answer {
          width: 100%;
        }
      }
    }

    &--practise {
      max-height: 540px;
      margin-left: -20px;
      box-sizing: border-box;
      padding-top: 8px;
      overflow: hidden;
      overflow-y: auto;
      @include flexBox;
      align-items: baseline;

      .question {
        &--type {
          flex-shrink: 0;
          line-height: 24px;
        }

        &--type {
          height: 24px;
          background: #6340c8;
          border-radius: 12px 0px 12px 12px;
          text-align: center;
          color: #fff;
          margin: 0 8px 0 4px;
          font-size: 12px;
          padding: 0 8px;
        }

        &--data {
          flex-grow: 1;

          h5 {
            line-height: 24px;
            color: #1f1f1f;
            @include flexBox;
            align-items: baseline;

            span {
              text-align: justify;
              margin-right: 10px;
            }

            .el-image {
              flex-shrink: 0;
              margin-top: -7px;
            }
          }
        }

        &--options {
          margin-top: 12px;

          .option {
            line-height: 24px;
            color: #666666;
            @include flexBox;
            align-items: baseline;
            margin: 10px 0;

            .el-image {
              margin-left: 10px;
              flex-shrink: 0;
              margin-top: -4px;
            }
          }
        }

        &--knowledge,
        &--answer {
          width: 100%;
          min-height: 46px;
          border-radius: 10px;
          background: #f0f0f0;
          box-sizing: border-box;
          padding: 10px 25px;
          line-height: 26px;
          text-align: justify;
        }

        &--knowledge {
          margin-top: 18px;
        }

        &--answer {
          margin-top: 14px;
        }
      }
    }
  }

  &-scroll {
    width: calc(100% + 20px);
    max-height: 540px;
    box-sizing: border-box;
    padding-right: 20px;
    overflow: hidden;
    overflow-y: auto;
  }
}

::v-deep {
  .el-dialog.is-fullscreen{
    margin-left: 124px;
    width: calc(100% - 124px);
  }
}
</style>
<style lang="scss">
.distribute-dialog .el-dialog {
  transform: translateY(-80%);
}
</style>
<template>
  <section class="homework">
    <div class="page-head">
      <div class="page-head--inner">
        <img class="bitmap" src="@assets/images/bitmap-head.png" alt />
        <div class="head-wrapper column">
          <breadcrumb />
          <div class="wrap-plate">
            <div class="single-search">
              <div class="search-ipt">
                <el-input
                  v-model.trim="searchForm.query_word"
                  maxlength="100"
                  placeholder="搜索作业名称"
                  clearable
                >
                  <span slot="prefix" class="iconfont">&#xe61c;</span>
                </el-input>
              </div>
              <el-button
                type="custom_primary search"
                size="medium"
                @click="searchData"
                >搜索</el-button
              >
              <el-button
                type="custom_warning"
                size="medium"
                @click="showDialog('homework')"
                >新建+</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="page-tab">
        <ul class="tabs bold">
          <li
            class="tab"
            :class="{ current: searchForm.sysub_id == item.sysub_id }"
            v-for="(item, index) in teachSubject.slice(0, 5)"
            :key="item.sysub_id"
            @click="changeTab(index)"
          >
            {{ item.sysub_name }}
          </li>
        </ul>
        <el-dropdown
          class="more-subject"
          placement="bottom-start"
          v-if="teachSubject.length > 5"
        >
          <p class="iconfont">&#xe8aa;</p>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in teachSubject.slice(
                5,
                teachSubject.length
              )"
              :key="item.sysub_id"
              @click.native="changeTab(index + 5)"
            >
              {{ item.sysub_name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="table-box">
      <div class="table-inner" ref="tableInner">
        <el-table :data="tableData" height="100%">
          <el-table-column
            align="center"
            prop="teles_hom_title"
            label="作业名称"
            min-width="13.09%"
          />
          <el-table-column
            align="center"
            prop="sysub_name"
            label="科目"
            min-width="7.6%"
          />
          <el-table-column
            align="center"
            prop="sccou_cha_title"
            label="章节(课堂)"
            min-width="10.73%"
          />
          <!-- <el-table-column align="center" label="课程时间" min-width="12.89%">
            <template slot-scope="scope">
              {{
                scope.row.teles_hom_assigntime * 1000 | formatTime("YYYY-MM-DD HH:mm")
              }}
            </template>
          </el-table-column> -->
          <!-- <el-table-column align="center" label="截止时间" min-width="13.69%">
            <template slot-scope="scope">
              {{ scope.row.teles_hom_deadlinetime * 1000 | formatTime("YYYY-MM-DD HH:mm") }}
            </template>
          </el-table-column> -->
          <!-- <el-table-column align="center" label="累计人数" min-width="6.86%">
            <template slot-scope="scope">{{ scope.row.teles_hom_classnum }}人</template>
          </el-table-column> -->
          <!-- <el-table-column align="center" label="已交" min-width="6.6%">
            <template slot-scope="scope">{{ scope.row.teles_hom_finishnum }}人</template>
          </el-table-column>
          <el-table-column align="center" label="未交" min-width="6.61%">
            <template slot-scope="scope">{{ scope.row.teles_hom_nofinishnum }}人</template>
          </el-table-column>
          <el-table-column align="center" label="批阅" min-width="6.64%">
            <template slot-scope="scope">{{ scope.row.teles_hom_reviewnum }}人</template>
          </el-table-column> -->
          <el-table-column align="center" label="操作" min-width="15.29%">
            <template slot-scope="scope">
              <div class="operation-buttons" style="width: 200px">
                <template v-if="scope.row.teles_hom_status == 10">
                  <el-button
                    v-if="scope.row.tecla_id == -1"
                    v-loading="compileLoad"
                    type="custom_primary"
                    size="mini"
                    plain
                    @click="getHomeworkDetail(scope.row.teles_hom_id)"
                    >编辑</el-button
                  >
                  <el-button
                    type="custom_success"
                    size="mini"
                    plain
                    @click="
                      toDistributeHomework(
                        scope.row.teles_hom_id,
                        scope.row.sccla_id,
                        scope.row.tecla_id
                      )
                    "
                    :disabled="isDistribute == 2"
                    >下发</el-button
                  >
                  <!-- <el-button type="custom_success" size="mini" plain @click="toDistributeHomework(scope.row.teles_hom_id,scope.row.sccla_id,scope.row.tecla_id)">下发</el-button> -->
                  <el-button
                    v-if="scope.row.tecla_id == -1"
                    type="custom_danger"
                    size="mini"
                    plain
                    @click="delHomework(scope.row.teles_hom_id)"
                    >删除</el-button
                  >
                </template>
                <el-button
                  type="custom_danger"
                  size="mini"
                  plain
                  v-if="scope.row.teles_hom_status == 20"
                  @click="toReadHomework(scope.row.teles_hom_id)"
                  >批阅</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table-foot custom-foot">
        <customPagination
          :current="tablePage.pageIndex"
          :total="tablePage.total"
          @pageChange="flippingPage"
        />
      </div>
    </div>
    <!-- 设置提交作业时间 -->
    <el-dialog
      title="设置提交作业时间"
      width="430px"
      :visible.sync="distributeDialog"
      class="distribute-dialog"
      @close="hideDialog('distribute')"
    >
      <div class="dialog-container--distribute">
        <div class="date-picker">
          <el-date-picker
            v-model="distributeForm.teles_hom_deadlinetime"
            type="datetime"
            placeholder="请选择提交作业时间"
            prefix-icon="none"
            format="yyyy-MM-dd HH:mm"
            :picker-options="pickerOptions"
            @change="choosetime"
          />
        </div>
      </div>
      <div slot="footer">
        <el-button
          type="custom_primary"
          :disabled="!distributeForm.teles_hom_deadlinetime"
          size="small"
          @click="distributeHomework"
          >确认下发</el-button
        >
      </div>
    </el-dialog>
    <!-- 新建、编辑作业 -->
    <el-dialog
      :title="(homeworkForm.teles_hom_id && '编辑') || '新建'"
      :visible.sync="homeworkDialog" 
      fullscreen 
      width="900px"
      :show-close="false"
      @close="hideDialog('homework')"
    >
      <div class="dialog-container--homework">
        <div class="form-wrapper">
          <el-form
            ref="homeworkForm"
            :model="homeworkForm"
            label-width="82px"
            :rules="homeworkRules"
          >
            <el-form-item label="科目" prop="sysub_id">
              <el-select
                v-model="homeworkForm.sysub_id"
                placeholder="请选择"
                filterable
                @change="get_school_course_list"
              >
                <el-option
                  v-for="item in teachSubject"
                  :key="item.sysub_id"
                  :label="item.sysub_name"
                  :value="item.sysub_id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="教材" prop="sccou_id">
              <el-select
                v-model="homeworkForm.sccou_id"
                placeholder="请选择"
                filterable
                @change="getChaptersTree"
              >
                <el-option
                  v-for="item in sccouData"
                  :key="item.sccou_id"
                  :label="item.title"
                  :value="item.sccou_id"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="课时章节" prop="chapter">
              <el-cascader
                v-model="homeworkForm.chapter"
                :options="chaptersTree"
                clearable
                :props="{ value: 'sccou_cha_id', label: 'sccou_cha_title' }"
                placeholder="请选择"
              />
            </el-form-item>
            <el-form-item label="标题" prop="teles_hom_title">
              <el-input
                v-model.trim="homeworkForm.teles_hom_title"
                maxlength="100"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="描述" prop="teles_hom_remark">
              <el-input
                v-model.trim="homeworkForm.teles_hom_remark"
                type="textarea"
                maxlength="200"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="选题">
              <el-select
                v-model="homeworkForm.syque_typ_id"
                placeholder="请选择题目类型"
                @change="searchQues"
              >
                <el-option
                  v-for="item in questionType"
                  :key="item.syque_typ_id"
                  :label="item.syque_typ_name"
                  :value="item.syque_typ_id"
                />
              </el-select>
              <!-- <el-select
                v-model="homeworkForm.que_id"
                filterable
                :remote="homeworkForm.hasOwnProperty('syque_typ_id')"
                clearable
                placeholder="请输入题目关键字"
                v-loadMore="loadMoreQues"
                :remote-method="filterQues"
                @change="queSelect"
              >
                <el-option
                  v-for="item in selectQues"
                  :key="item.teque_id"
                  :label="item.teque_title"
                  :value="item.teque_id"
                />
              </el-select> -->
              <!-- 自定义下拉 queSelect 选中的题 valkey选中的key  title显示的文案 remoteMethod输入框输入的文字  reachBottom滚动触底  more是否更多-->
              <richSelect style="margin-bottom: 12px;" :list="selectQues" placeholder="请输入题目关键字" v-model="homeworkForm.que_id" @queSelect="queSelect" @remoteMethod="filterQues" @reachBottom="loadMoreQues" :more="this.selectQues.length < this.selectQuesPage.total"></richSelect>
              <div class="homework-questions" v-if="atPresentQues.teque_id">
                <ul class="dialog-question-list">
                  <li class="question-item">
                    <span class="question--type">{{ atPresentQues.syque_typ_name }}</span>
                    <div class="question--data">
                      <h5 class="bold">
                        <span class="richinline" v-html="atPresentQues.teque_title"></span>
                        <el-image
                          style="width: 60px; height: 38px"
                          v-if="atPresentQues.teque_title_image"
                          :src="formatfile(atPresentQues.teque_title_image)"
                          fit="cover"
                          :preview-src-list="[formatfile(atPresentQues.teque_title_image)]"
                        />
                      </h5>
                      <!-- 组合题 -->
                      <template v-if="atPresentQues.teque_ismakeup == 10">
                        <p class="combination--title">{{ atPresentQues.teque_describe }}</p>
                        <ol class="combination--subtopic">
                          <li
                            class="subtopic-item"
                            v-for="(citem, cindex) in atPresentQues.children_question"
                            :key="citem.teque_id"
                          >
                            <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                            <div class="subtopic--data">
                              <p class="subtopic--title">
                                <span class="richinline" v-html="citem.teque_title"></span>
                                <el-image
                                  style="width: 60px; height: 38px"
                                  v-if="citem.teque_title_image"
                                  :src="formatfile(citem.teque_title_image)"
                                  fit="cover"
                                  :preview-src-list="[
                                    formatfile(citem.teque_title_image),
                                  ]"
                                />
                              </p>
                              <dl
                                class="subtopic--options"
                                v-if="
                                  citem.syque_typ_id == 1 ||
                                  citem.syque_typ_id == 2 ||
                                  citem.syque_typ_id == 5
                                "
                              >
                                <dt
                                  class="option"
                                  v-for="o in citem.question_item"
                                  :key="o.teque_ite_id"
                                >
                                  <span
                                    >{{ o.teque_ite_code }}.
                                    <span class="richinline" v-html="o.teque_ite_title"></span></span
                                  >
                                  <el-image
                                    style="width: 54px; height: 32px"
                                    v-if="o.teque_ite_image"
                                    :src="formatfile(o.teque_ite_image)"
                                    fit="cover"
                                    :preview-src-list="[
                                      formatfile(o.teque_ite_image),
                                    ]"
                                  />
                                </dt>
                              </dl>
                              <p
                                class="subtopic--knowledge"
                                v-if="citem.teles_kno_title"
                              >
                                {{ citem.teles_kno_title }}
                              </p>
                              <div class="subtopic--answer">
                                <p
                                  class="answer"
                                  v-if="!$isEmpty(citem.sure_answer)"
                                >
                                  答案：<span v-html="citem.sure_answer.join(',')"></span>
                                </p>
                                <p class="analysis" v-if="citem.teque_analysis">
                                  <span class="richinline" v-html="citem.teque_analysis"></span>
                                </p>
                              </div>
                            </div>
                          </li>
                        </ol>
                      </template>
                      <!-- 其他题型 -->
                      <template v-else>
                        <ul
                          class="question--options"
                          v-if="
                            atPresentQues.syque_typ_id == 1 ||
                            atPresentQues.syque_typ_id == 2 ||
                            atPresentQues.syque_typ_id == 5
                          "
                        >
                          <li
                            class="option"
                            v-for="o in atPresentQues.children_question"
                            :key="o.teque_ite_id"
                          >
                            <span class="flex"
                              >{{ o.teque_ite_code }}. <span class="richinline" v-html="o.teque_ite_title"></span></span
                            >
                            <el-image
                              style="width: 54px; height: 32px"
                              v-if="o.teque_ite_image"
                              :src="formatfile(o.teque_ite_image)"
                              fit="cover"
                              :preview-src-list="[formatfile(o.teque_ite_image)]"
                            />
                          </li>
                        </ul>
                        <p class="question--knowledge" v-if="atPresentQues.teles_kno_title">
                          {{ atPresentQues.teles_kno_title }}
                        </p>
                        <div class="question--answer">
                          <p class="answer" v-if="!$isEmpty(atPresentQues.sure_answer)">
                            答案：<span class="richinline" v-html="atPresentQues.sure_answer.join(',')"></span>
                          </p>
                          <p class="analysis" v-if="atPresentQues.teque_analysis">
                            <span class="richinline" v-html="atPresentQues.teque_analysis"></span>
                          </p>
                        </div>
                      </template>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <el-button
                type="custom_primary"
                size="small"
                @click="appendHomeworkQue"
                >+添加题</el-button>
              </div>
            </el-form-item>
          </el-form>
          <div class="homework-questions">
            <ul class="dialog-question-list">
              <li
                class="question-item"
                :class="{ combination: item.teque_ismakeup == 10 }"
                v-for="(item, index) in homeworkQues"
                :key="item.teque_id"
              >
                <span class="question--num"
                  >{{ (index + 1 > 9 && index + 1) || `0${index + 1} ` }}.</span
                >
                <span class="question--type">{{ item.syque_typ_name }}</span>
                <div class="question--data">
                  <h5 class="bold">
                    <span class="richinline" v-html="item.teque_title"></span>
                    <el-image
                      style="width: 60px; height: 38px"
                      v-if="item.teque_title_image"
                      :src="formatfile(item.teque_title_image)"
                      fit="cover"
                      :preview-src-list="[formatfile(item.teque_title_image)]"
                    />
                  </h5>
                  <!-- 组合题 -->
                  <template v-if="item.teque_ismakeup == 10">
                    <p class="combination--title">{{ item.teque_describe }}</p>
                    <ol class="combination--subtopic">
                      <li
                        class="subtopic-item"
                        v-for="(citem, cindex) in item.children_question"
                        :key="citem.teque_id"
                      >
                        <span class="subtopic--num">（{{ cindex + 1 }}）</span>
                        <div class="subtopic--data">
                          <p class="subtopic--title">
                            <span class="richinline" v-html="citem.teque_title"></span>
                            <el-image
                              style="width: 60px; height: 38px"
                              v-if="citem.teque_title_image"
                              :src="formatfile(citem.teque_title_image)"
                              fit="cover"
                              :preview-src-list="[
                                formatfile(citem.teque_title_image),
                              ]"
                            />
                          </p>
                          <dl
                            class="subtopic--options"
                            v-if="
                              citem.syque_typ_id == 1 ||
                              citem.syque_typ_id == 2 ||
                              citem.syque_typ_id == 5
                            "
                          >
                            <dt
                              class="option"
                              v-for="o in citem.question_item"
                              :key="o.teque_ite_id"
                            >
                              <span
                                >{{ o.teque_ite_code }}.
                                <span class="richinline" v-html="o.teque_ite_title"></span></span
                              >
                              <el-image
                                style="width: 54px; height: 32px"
                                v-if="o.teque_ite_image"
                                :src="formatfile(o.teque_ite_image)"
                                fit="cover"
                                :preview-src-list="[
                                  formatfile(o.teque_ite_image),
                                ]"
                              />
                            </dt>
                          </dl>
                          <p
                            class="subtopic--knowledge"
                            v-if="citem.teles_kno_title"
                          >
                            {{ citem.teles_kno_title }}
                          </p>
                          <div class="subtopic--answer">
                            <p
                              class="answer"
                              v-if="!$isEmpty(citem.sure_answer)"
                            >
                              答案：<span class="richinline" v-html="citem.sure_answer.join(',')"></span>
                            </p>
                            <p class="analysis" v-if="citem.teque_analysis">
                              <span class="richinline" v-html="citem.teque_analysis"></span>
                            </p>
                          </div>
                        </div>
                      </li>
                    </ol>
                  </template>
                  <!-- 其他题型 -->
                  <template v-else>
                    <ul
                      class="question--options"
                      v-if="
                        item.syque_typ_id == 1 ||
                        item.syque_typ_id == 2 ||
                        item.syque_typ_id == 5
                      "
                    >
                      <li
                        class="option"
                        v-for="o in item.children_question"
                        :key="o.teque_ite_id"
                      >
                        <span class="flex"
                          >{{ o.teque_ite_code }}. <span class="richinline" v-html="o.teque_ite_title"></span></span
                        >
                        <el-image
                          style="width: 54px; height: 32px"
                          v-if="o.teque_ite_image"
                          :src="formatfile(o.teque_ite_image)"
                          fit="cover"
                          :preview-src-list="[formatfile(o.teque_ite_image)]"
                        />
                      </li>
                    </ul>
                    <p class="question--knowledge" v-if="item.teles_kno_title">
                      {{ item.teles_kno_title }}
                    </p>
                    <div class="question--answer">
                      <p class="answer" v-if="!$isEmpty(item.sure_answer)">
                        答案：<span class="richinline" v-html="item.sure_answer.join(',')"></span>
                      </p>
                      <p class="analysis" v-if="item.teque_analysis">
                        <span class="richinline" v-html="item.teque_analysis"></span>
                      </p>
                    </div>
                  </template>
                </div>
                <div class="operate-group iconfont">
                  <!-- 移除作业题 -->
                  <el-button
                    type="custom_primary"
                    circle
                    @click="removeHomeworkQue(index)"
                    >&#xe620;</el-button
                  >
                  <!-- 作业题排序调整 -->
                  <template v-if="homeworkQues.length > 1">
                    <!-- 上移 -->
                    <el-button
                      class="lighter"
                      type="custom_info"
                      circle
                      v-if="index > 0"
                      @click="changeSort('u', index)"
                      >&#xe657;</el-button
                    >
                    <!-- 下移 -->
                    <el-button
                      class="lighter"
                      type="custom_info"
                      circle
                      v-if="index < homeworkQues.length - 1"
                      @click="changeSort('d', index)"
                      >&#xe645;</el-button
                    >
                  </template>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button
          type="custom_info"
          size="small"
          @click="hideDialog('homework')"
          >取 消</el-button
        >
        <el-button
          type="custom_primary"
          size="small"
          @click="addHomework"
          :disabled="btnload"
          v-loading="btnload"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  $schoolworkList,
  $homeworkDetail,
  $addHomework,
  $editHomework,
  $distributeHomework,
  $delHomework,
  $getQuesList,
  $attendWork,
} from "@api/homeworkExam";
import { $getChapters,$get_school_course_list } from "@api/home";
import { $getTeachSubject } from "@api/teaching";
import { mapState } from "vuex";
import { formatFile, formatTimeStamp } from "@utils";
import customPagination from "@comp/customPagination";
export default {
  name: "he_schoolwork",
  components: { customPagination },
  computed: {
    ...mapState("common", ["questionType"]),
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
  },
  data() {
    return {
      grade: null,
      class: null,
      isDistribute: 2,
      searchForm: {}, // 筛选数据
      teachSubject: [], // 教师所授科目
      tableData: [], // 作业列表数据
      /* 表格分页参数 */
      tablePage: {
        pageIndex: 1,
        total: 0,
      },
      sccouData: [], // 教材（树）
      chaptersTree: [], // 章节数据（树）
      /* 下发作业 */
      distributeDialog: false,
      distributeForm: {},
      /* 作业新建、编辑 */
      homeworkDialog: false,
      homeworkForm: {},
      homeworkQues: [], // 作业内选中题目
      selectQues: [], // 可选题目列表
      /* 可选题目分页参数 */
      selectQuesPage: {
        index: 1,
        total: 0,
      },
      selectQuesKey: "", // 可选题目筛选关键字
      btnload: false,
      //编辑按钮等待
      compileLoad:false,
      homeworkRules: {
        sysub_id: [
          { required: true, message: "请选择作业相关科目", trigger: "change" },
        ],
        chapter: [
          { required: true, message: "请选择作业相关章节", trigger: "change" },
        ],
        teles_hom_title: [
          { required: true, message: "请填写作业标题", trigger: "blur" },
        ],
        teles_hom_remark: [
          { required: true, message: "请填写作业描述", trigger: "change" },
        ],
      },
      pickerOptions: {
        disabledDate(time) {
          const dateTime = new Date();
          const startDateTime = dateTime.setDate(dateTime.getDate() - 1);
          return time.getTime() < new Date(startDateTime).getTime();
        },
        selectableRange: `00:00:00 - 23:59:59`,
      },
      //新建切换题目小题展示
      atPresentQues:{},
    };
  },
  async created() {
    let { id } = this.$route.params;
    id = id.split(",");
    this.grade = id[0];
    this.class = id[1];
    await this.getTeachSubject();
    this.getData();
  },
  /** 路由keepAlive为true时，页面数据更新 */
  async activated() {
    await this.getTeachSubject();
  },
  /** 页面跳转/返回前的回调 */
  beforeRouteLeave(to, from, next) {
    this.$routeAlive(to, from, next, ["HE_SCHOOLWORKREAD"]);
  },

  watch: {
  },
  methods: {
    /** 获取教材数据 */
    async getData() {
      let params = {
        ...this.searchForm,
        sccla_id: this.class,
        pageindex: this.tablePage.pageIndex,
      };
      let { data: res } = await $schoolworkList(params);
      this.tablePage.total = res.allcount;
      this.tableData = res.data;
      this.isDistribute = res.isDistribute;

      this.$forceUpdate();
    },
    /** 翻页 */
    flippingPage(val) {
      this.tableData = [];
      this.tablePage.pageIndex = val;
      this.getData();
    },
    /** 获取教师所授科目 */
    async getTeachSubject() {
      let { data } = await $getTeachSubject(this.class);
      this.teachSubject = data;
      if (this.searchForm.sysub_id) {
        data.map((item, index) => {
          if (item.sysub_id == this.searchForm.sysub_id) this.changeTab(index);
        });
      } else {
        this.searchForm = {
          ...this.searchForm,
          sysub_id: data[0].sysub_id,
        };
      }
    },
    /** 切换数据相关科目 */
    changeTab(index) {
      this.searchForm = {
        sccla_id: this.class,
        sysub_id: this.teachSubject[index].sysub_id,
      };
      if (index > 4) {
        let firData = this.teachSubject[index];
        let replaceData = this.teachSubject[4];
        this.teachSubject[4] = { ...firData };
        this.teachSubject[index] = { ...replaceData };
      }
      this.searchData();
    },
    /** 筛选数据 */
    searchData() {
      this.tableData = [];
      this.tablePage.pageIndex = 1;
      this.getData();
    },
    /** 获取教材 */
    async get_school_course_list(id,isdelete=true) {
      //清理数据
      this.sccouData = [];
      this.chaptersTree = [];
      if(isdelete){
        // 删除教材选中的id
        delete this.homeworkForm.sccou_id;
        // 删除选中的章节
        delete this.homeworkForm.chapter;
      }
      let { sysub_id } = this.homeworkForm;
      if (sysub_id || id) {
        let params = {
          grade: this.grade,
          sysub_id: sysub_id || id
        };
        let { data } = await $get_school_course_list(params);
        if (!this.$isEmpty(data)) {
          this.sccouData = data;
        }
        this.$forceUpdate();
      }
    },
    /** 获取章节数据(树) */
    async getChaptersTree(id,isdelete=true) {
      if(isdelete){
        delete this.homeworkForm.chapter;
      }
      this.chaptersTree = [];
      let { sysub_id,sccou_id } = this.homeworkForm;
      if (sccou_id || id) {
        let params = {
          grade: this.grade,
          sysub_id: sysub_id,
          is_trees: 1,
          sccou_id:sccou_id || id
        };
        let { data } = await $getChapters(params);
        if (!this.$isEmpty(data)) {
          data = data[0];
          data.forEach((item) => {
            if (this.$isEmpty(item.children)){
              delete item.children;
            }else {
              item.children.forEach((citem) => {
                if (this.$isEmpty(citem.children)) delete citem.children;
              });
            }
          });
        }
        this.chaptersTree = data;
        this.$forceUpdate();
      }
    },
    /**  获取可选题目列表 */
    async getQues() {
      let { chapter } = this.homeworkForm;
          if (chapter.length < 2) {
            this.$message.warning("请先选择章节");
            this.btnload = false;
            return false;
          }
      let params = {
        syque_typ_id: this.homeworkForm.syque_typ_id,
        query_word: this.selectQuesKey,
        work: 'schoolwork',
        pageindex: this.selectQuesPage.index,
        sccou_cha_id: chapter[chapter.length - 1],
      };
      delete this.homeworkForm.que_id;
      let { data: res } = await $getQuesList(1, params);
      this.selectQues = [...this.selectQues, ...res.data];
      this.selectQuesPage.total = res.allcount;
      this.$forceUpdate();
    },
    /** 筛选可选题目列表 */
    searchQues() {
      this.selectQues = [];
      this.selectQuesKey = "";
      this.selectQuesPage.index = 1;
      this.getQues();
    },
    /**
     * 根据关键字筛选可选题目
     * @param {string} query 可选题目关键字
     */
    filterQues(query) {
      this.selectQuesKey = query;
      this.selectQues = [];
      this.selectQuesPage.index = 1;
      this.getQues();
    },
    /** 更多可选题目数据加载 */
    loadMoreQues() {
      if (this.selectQues.length == this.selectQuesPage.total) return;
      this.selectQuesPage.index++;
      this.getQues();
    },
    /**
     * 添加选中题目
     */
    appendHomeworkQue() {
      if (!this.homeworkForm.syque_typ_id || !this.homeworkForm.que_id) return;
      let { que_id } = this.homeworkForm;
      delete this.homeworkForm.syque_typ_id;
      delete this.homeworkForm.que_id;
      if (!this.$_.find(this.homeworkQues, ["teque_id", que_id])) {
        let que = this.$_.find(this.selectQues, ["teque_id", que_id]);
        this.homeworkQues.push(que);
      } else {
        this.$message.warning("选中项已存在作业题目列表中");
      }
      this.selectQues = [];
      this.selectQuesKey = "";
      this.selectQuesPage.index = 1;
      this.atPresentQues={}
      this.$forceUpdate();
    },
    /**
     * 移除选中题目
     * @param {number} index 题目下标
     */
    removeHomeworkQue(index) {
      this.$_.pullAt(this.homeworkQues, index);
      this.$forceUpdate();
    },
    /**
     * 调整题目顺序
     * @param {string} type 调整类型
     * @param {number} index 题目下标
     */
    changeSort(type, index) {
      const dot_1 = this.homeworkQues[index];
      let dot_2;
      /* 下移 */
      if (type == "d") {
        dot_2 = this.homeworkQues[index + 1];
        this.homeworkQues[index + 1] = { ...dot_1 };
      }
      /* 上移 */
      if (type == "u") {
        dot_2 = this.homeworkQues[index - 1];
        this.homeworkQues[index - 1] = { ...dot_1 };
      }
      this.homeworkQues[index] = { ...dot_2 };
      this.$forceUpdate();
    },
    /** 添加作业 */
    addHomework() {
      this.$refs.homeworkForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let { chapter } = this.homeworkForm;
          if (chapter.length < 2) {
            this.$message.warning("该课时未配置小节");
            this.btnload = false;
            return false;
          }
          let params = {
            ...this.homeworkForm,
            sccla_id: this.class,
            teles_hom_type: 2,
            tecla_grade: this.grade,
            sccou_cha_id: chapter[chapter.length - 1],
          };
          delete params.chapter;
          params.question = [];
          let homeworkQues = this.$_.cloneDeep(this.homeworkQues);
          homeworkQues.map((item) => {
            let que = {
              que_id: item.teque_id,
              teles_hom_ite_ismakeup: item.teque_ismakeup,
            };
            params.question.push(que);
          });
          if (params.teles_hom_id) return this.editHomework(params); // 存在作业id，执行作业编辑
          let res = await $addHomework(params);
          this.btnload = false;
          if (res) {
            this.hideDialog("homework"); // 关闭表单弹窗
            this.getData();
            this.$message({
              type: "success",
              duration: 1500,
              message: "作业添加成功",
            });
          }
        }
      });
    },
    /**
     * 获取作业详情
     * @param {number} id 作业id
     */
    async getHomeworkDetail(id) {
      this.compileLoad=true
      let { data } = await $homeworkDetail(id);
      let form = {},
      formKey = [
        "teles_hom_id",
        "teles_hom_title",
        "teles_hom_remark",
        "sysub_id",
        "sccou_id",
      ];
      formKey.map((item) => (form[item] = data[item]));
      form.chapter=[data.sccou_cha_pid, data.sccou_cha_id]
      this.homeworkForm = JSON.parse(JSON.stringify(form));
      // 获取教材列表
      this.get_school_course_list(data.sysub_id,false)
      // 获取课时章节
      await this.getChaptersTree(data.sysub_id,false);
      this.homeworkQues = [...data.question];
      this.compileLoad=false
      this.showDialog("homework");
      
    },
    /**
     * 编辑作业
     * @param {object} params 表单数据
     */
    async editHomework(params) {
      let res = await $editHomework(params);
      this.btnload = false;
      if (res) {
        this.hideDialog("homework"); // 关闭表单弹窗
        this.getData();
        this.$message({
          type: "success",
          duration: 1500,
          message: "作业编辑保存成功",
        });
      }
    },
    /**
     * 删除作业
     * @param {number} id 作业id
     */
    delHomework(id) {
      this.$msgbox({
        title: "删除",
        message: "确认删除选中作业？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          let res = await $delHomework(id);
          if (res) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "作业已删除！",
            });
            this.tablePage.total =
              (this.tablePage.total - 1 < 0 && 0) || this.tablePage.total - 1;
            if (this.tableData.length == 1 && this.tablePage.pageIndex != 1) {
              this.tablePage.pageIndex = this.tablePage.pageIndex - 1;
            }
            this.getData();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /** 下发作业 */
    async distributeHomework() {
      let teles_hom_deadlinetime = formatTimeStamp(
        this.distributeForm.teles_hom_deadlinetime
      );
      let params = {
        teles_hom_id: this.distributeForm.teles_hom_id,
        teles_hom_deadlinetime,
      };
      this.hideDialog("distribute");
      let res = await $distributeHomework(params);
      if (res) {
        this.getData();
        this.$message({
          type: "success",
          duration: 1500,
          message: "作业已下发！请提醒学生完成作业",
        });
      }
    },
    /** 下发作业时间选中 */
    choosetime() {
      let choose_time = this.distributeForm.teles_hom_deadlinetime.getTime();
      let cur_time = new Date().getTime();
      if (choose_time < cur_time) {
        this.distributeForm.teles_hom_deadlinetime = new Date();
        return this.$message.warning("不可选择当前时间之前的时间！");
      }
    },
    /**
     * 开启弹窗
     * @param {string} type 弹窗类型
     */
    showDialog(type) {
      this[`${type}Dialog`] = true;
    },
    /**
     * 关闭弹窗
     * @param {string} type 弹窗类型
     */
    hideDialog(type) {
      this[`${type}Dialog`] = false;
      if (this[`${type}Form`]) {
        this[`${type}Form`] = {};
        if (type == "homework") {
          this.homeworkQues = [];
          this.selectQues = [];
          this.resetForm(`${type}Form`);
        }
      }
    },
    /** 重置表单 */
    resetForm(ref) {
      this.$refs[ref].resetFields();
    },
    /**
     * 批阅作业
     * @param {number} id 作业id
     */
    toReadHomework(id) {
      this.$router.push({
        name: "HE_SCHOOLWORKREAD",
        params: {
          class: this.$route.params.class,
          id: this.$route.params.id,
          homework: id,
        },
      });
    },
    /**
     * 下发作业
     * @param {number} id 作业id
     */
    async toDistributeHomework(id, sccla_id, tecla_id) {
      // 第一次打开手写板
      if (!sessionStorage.getItem("openServer")) {
        sessionStorage.setItem("openServer", true);
        //打开手写板
        let url = "RunZhiYuanTongApServer://" + sccla_id + ",lisi,wusong";
        window.open(url, "_blank");
      }

      //启动前请求下开启ap的接口
     let params = { teles_hom_id: id };
      let res = await $attendWork(params);
      if (res.status == 200) {
        this.$router.push({
          name: "HE_SCHOOLWORKDISTRIBUTE",
          params: {
            class: this.$route.params.class,
            id: this.$route.params.id,
            homework: id,
            sccla_id: sccla_id,
            tecla_id: tecla_id,
          },
        });
      } else {
        return this.$message.warning(res.msg);
      }
    },
    // 切换题
    queSelect(e){
      console.log(e)
      if(e.id){
        this.atPresentQues=e.item
      }else{
        this.atPresentQues={}
      }
      // this.atPresentQues={}
      // this.selectQues.forEach(item=>{
      //   if(item.teque_id==e){
      //     this.atPresentQues=item
      //   }
      // })
    }
  },
};
</script>
